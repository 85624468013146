<template>
  <div
    v-if="visible"
    class="wrap"
  >
    <span
      class="close"
      @click="close"
    >
      <span class="close-line"></span>
      <span class="close-line"></span>
    </span>
    <div
      class="content"
      :style="style"
    >
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  components: {

  },
  props: {
    contentWidth: {
      type: String,
      default: '1000px'
    }
  },
  data() {
    return {
      visible: false
    }
  },
  computed: {
    style() {
      return {
        width: this.contentWidth
      }
    }
  },
  methods: {
    show() {
      this.visible = true
    },
    close() {
      this.visible = false
    }
  }
}
</script>
<style lang="scss" scoped>
.wrap {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.4);
}
.close {
  position: absolute;
  top: 60px;
  right: 60px;
  width: 35px;
  height: 35px;
  border: 1px solid white;
  border-radius: 50%;
  color: white;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  .close-line {
    position: absolute;
    top: 50%;
    display: inline-block;
    width: 30px;
    height: 4px;
    background: white;
    transform-origin: 50% 50%;
    &:first-child {
      transform: translateY(-50%) rotate(45deg);
    }
    &:last-child {
      transform: translateY(-50%) rotate(-45deg);
    }
  }
}
.content {
  width: 1000px;
  max-height: calc(100% - 80px);
  overflow-x: scroll;
  overflow-y: auto;
  margin: 40px auto;
}
</style>