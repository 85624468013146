<template>
  <div class="sub-menu">
    <template
      v-for="(item, index) in nav"
      :key="index"
    >
      <div
        class="sub-menu-item"
        :class="{'sub-menu-item--active': highLight(item)}"
        @click="jump(item)"
      >
        <span class="sub-menu-label">{{item.name}}</span>
        <span
          class="sub-menu-arrow"
          v-if="item.children && item.children.length"
        >&gt;</span>
        <div
          class="sub-menu-child-wrap"
          v-if="item.children && item.children.length"
        >
          <ZcSubMenu :nav="item.children" />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "ZcSubMenu",
  components: {

  },
  props: {
    nav: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  methods: {
    jump(route) {
      this.$router.push(route.path)
    },
    highLight(item) {
      return this.$route.path === item.path || (item.children && item.children.map(c => c.path).includes(this.$route.path))
    }
  }
}
</script>
<style lang="scss" scoped>
.sub-menu {
  display: flex;
  flex-direction: column;
  background: #e61d0e;
}
.sub-menu-item {
  position: relative;
  min-width: 12.9rem;
  text-align: center;
  cursor: pointer;
  padding: 1.5rem 0;
  color: white;
  font-size: 1.6rem;
  line-height: 1.6rem;
  font-weight: normal;
  .sub-menu-child-wrap {
    visibility: hidden;
  }
  &:hover {
    font-size: 1.8rem;
    font-weight: 500;
    .sub-menu-child-wrap {
      visibility: visible;
    }
  }
  &.sub-menu-item--active {
    font-size: 1.8rem;
    font-weight: 500;
    .sub-menu-child-wrap {
      visibility: visible;
    }
  }
}
.sub-menu-arrow {
  position: absolute;
  right: 1rem;
}
.sub-menu-child-wrap {
  position: absolute;
  top: 0;
  right: -100%;
}
</style>
