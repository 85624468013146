<template>
  <div class="menu">
    <template
      v-for="(item, index) in nav"
      :key="index"
    >
      <div
        class="menu-item"
        :class="{'high-light': highLight(item)}"
        @click="jump(item)"
      >
        <span class="menu-label">{{item.name}}
          <span
            class="menu-arrow"
            v-if="item.children && item.children.length"
          >
            <ZcIconArrowDown fill="#ffffff" />
          </span>
        </span>
        <div
          class="sub-menu-wrap"
          v-if="item.children && item.children.length"
        >
          <ZcSubMenu :nav="item.children" />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import ZcSubMenu from './ZcSubMenu.vue'
import ZcIconArrowDown from './ZcIconArrowDown.vue'
export default {
  name: "ZcMenu",
  components: {
    ZcSubMenu,
    ZcIconArrowDown
  },
  props: {
    nav: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  data() {
    return {}
  },
  computed: {

  },
  watch: {

  },
  mounted() {

  },
  methods: {
    jump(route) {
      if (route.children) return
      this.$router.push(route.path)
    },
    highLight(item) {
      return this.$route.path === item.path || (item.children && item.children.map(c => c.path).includes(this.$route.path))
    }
  }
}
</script>
<style lang="scss" scoped>
.menu {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 1.8rem;
}
.menu-item {
  position: relative;
  min-width: 8rem;
  text-align: center;
  cursor: pointer;
  padding: 0 2vw;
  color: white;
  line-height: 1.8rem;
  .sub-menu-wrap {
    visibility: hidden;
  }
  &:hover {
    .sub-menu-wrap {
      visibility: visible;
    }
  }
}
.menu-label {
  position: relative;
}
.menu-arrow {
  position: absolute;
  right: -2rem;
  top: 50%;
  transform: translateY(-50%);
  z-index: 11;
  font-size: 1.2rem;
}
.sub-menu-wrap {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  padding-top: 4.8rem;
}
.high-light {
  font-weight: bold;
}
</style>
