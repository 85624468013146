import { createRouter, createWebHashHistory } from "vue-router"
import nav from './nav'
import Frame from '@/views/Frame.vue'
import Main from '@/views/Main.vue'

const routes = [
  {
    path: '/',
    name: 'frame',
    component: Frame,
    redirect: '/main',
    children: [
      {
        path: '/main',
        name: 'Main',
        component: Main,
        redirect: '/main/home',
        children: nav
      }
    ]
  },
  // {
  //   path: "/about",
  //   name: "About",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/About.vue"),
  // },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
