<template>
  <svg
    t="1623913314008"
    class="icon"
    viewBox="0 0 1024 1024"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    p-id="1180"
    :width="size"
    :height="size"
  >
    <path
      d="M904.533333 311.466667c-17.066667-17.066667-42.666667-17.066667-59.733333 0L512 644.266667 179.2 311.466667c-17.066667-17.066667-42.666667-17.066667-59.733333 0-17.066667 17.066667-17.066667 42.666667 0 59.733333l362.666666 362.666667c8.533333 8.533333 19.2 12.8 29.866667 12.8s21.333333-4.266667 29.866667-12.8l362.666666-362.666667c17.066667-17.066667 17.066667-42.666667 0-59.733333z"
      p-id="1181"
      :fill="fill"
    ></path>
  </svg>
</template>

<script>
export default {
  name: "ZcIconArrowDown",
  components: {

  },
  props: {
    fill: {
      type: String,
      default: '#ffffff'
    },
    size: {
      type: Number,
      default: 12
    }
  }
}
</script>
