<template>
  <div>

    <div class="banner-carousel">
      <el-carousel
        ref="bannerCarousel"
        height="39vw"
        direction="vertical"
        :autoplay="true"
        indicator-position="none"
        :initial-index="currentBannerIndex"
        @change="carouselChange"
      >
        <el-carousel-item
          class="carousel-item"
          v-for="(item, index) in banners"
          :key="index"
          :style="{'background-image': 'url('+item.bgImgSrc+')'}"
        >
          <div class="banner-carousel--content">
            <!-- <img
              class="banner-carousel--arrow"
              :src="bannerArrowImgSrc"
              @click="setIndicatorNext"
            > -->
          </div>
        </el-carousel-item>
      </el-carousel>
      <div class="banner-indicator">
        <div
          class="banner-indicator--line"
          :style="{'height': (banners.length -1) * 4.8 + 'rem'}"
        >
          <template
            v-for="(item, index) in banners"
            :key="index"
          >
            <div
              class="banner-indicator--dot"
              :style="{'top': index * 4.8 + 'rem'}"
            >
              <span
                class="banner-indicator--dot_show"
                v-if="currentBannerIndex === index"
              >{{'0' + (currentBannerIndex + 1)}}</span>
              <span
                class="banner-indicator--dot_hide"
                v-if="currentBannerIndex !== index"
                @click="setIndicator(index)"
              ></span>
            </div>
          </template>
        </div>
      </div>
    </div>

    <div class="zone breif">
      <div class="area-title">
        <p class="title">至灿供应链</p>
      </div>
      <p class="breaf-content">
        选至灿 采购省心 品质放心 服务上心
      </p>
    </div>

    <div class="zone advantage">
      <div class="area-title">
        <p class="title">核心优势</p>
      </div>
      <div class="advantage-content">
        <div
          class="advantage-content--item"
          v-for="(item, index) in advantages"
          :key="index"
          @click="jump('/main/advantage')"
        >
          <div
            class="advantage-content--item_c"
            :style="{'background-image': 'url('+item.bg+')'}"
          >
            <div class="advantage-content--item_cc">
              <p class="advantage-content--item_ct">{{item.title}}</p>
              <p
                class="advantage-content--item_ci"
                v-for="(sub, idx) in item.desc"
                :key="idx"
              >{{sub}}</p>
            </div>
          </div>
          <div
            class="advantage-content--item_f"
            :style="{'background-image': 'url('+item.hoverBg+')'}"
          >
            <div class="advantage-content--item_fb">
              <div
                class="advantage-content--item_fc"
                v-for="(h, idx) in item.hover"
                :key="idx"
              >
                <p class="advantage-content--item_ft">{{h.title}}</p>
                <p class="advantage-content--item_fi">{{h.desc}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="zone business">
      <div class="area-title">
        <p class="title">业务范畴</p>
      </div>
      <div class="business-content">
        <template
          v-for="(item, index) in business"
          :key="index"
        >
          <div class="business-item">
            <p class="business-item--title">{{item.idx}}</p>
            <p class="business-item--title">{{item.title}}</p>
            <img
              class="business-item--img"
              :src="item.imgSrc"
            >
          </div>
        </template>
      </div>
    </div>

    <div class="zone brand">
      <div class="area-title">
        <p class="title">服务品牌</p>
      </div>
      <div class="brand-content">
        <el-carousel
          class="brand-carousel"
          ref="brandCarousel"
          height="23vw"
          indicator-position="none"
          :autoplay="true"
          arrow="always"
        >
          <el-carousel-item
            class="brand-carousel-item"
            v-for="(item, index) in brands"
            :key="index"
          >
            <div
              class="brand-carousel-item_c"
              :style="{'background-image': 'url('+item.bg+')'}"
            >

            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>

    <div class="zone news">
      <div class="area-title">
        <p class="title">行业新闻</p>
      </div>
      <div class="news-content">
        <div
          class="new-content-arrow new-content-arrow--left"
          @click="scrollNews('left')"
        >
          <ZcIconArrowDown :size="30" />
        </div>
        <div
          class="new-content-arrow new-content-arrow--right"
          @click="scrollNews('right')"
        >
          <ZcIconArrowDown :size="30" />
        </div>
        <div
          class="news-content-scroll"
          ref="news"
        >
          <template
            v-for="(item, index) in news"
            :key="index"
          >
            <div
              :ref="'newsItem'+index"
              class="news-content--item"
              :class="{'news-content--item_active': item.isActive}"
              @click="viewNews(index)"
            >
              <div
                class="news-content--item_img"
                :style="{'background-image': 'url('+item.imgSrc+')'}"
              ></div>
              <div class="news-content--item_info">
                <div class="news-content--item_it">{{item.title}}</div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>

    <Dialog
      ref="newsOne"
      content-width="600px"
    >
      <div>
        <img
          style="width: 100%;height:auto;"
          src="../assets/images/home/v2/news1/01_png.png"
          alt=""
        >
        <img
          style="width: 100%;height:auto;"
          src="../assets/images/home/v2/news1/02_png.png"
          alt=""
        >
        <img
          style="width: 100%;height:auto;"
          src="../assets/images/home/v2/news1/03_png.png"
          alt=""
        >
      </div>
    </Dialog>
  </div>
</template>

<script>
import ZcIconArrowDown from '@/components/ZcIconArrowDown.vue'
import Dialog from '@/components//dialog/index.vue'
export default {
  name: "Home",
  components: {
    ZcIconArrowDown,
    Dialog
  },
  data() {
    return {
      bannerArrowImgSrc: require('@/assets/images/home/ys/banner-arrow.png'),
      currentBannerIndex: 0,
      banners: [
        {
          firstImgSrc: require('@/assets/images/home/ys/banner-one-first.png'),
          secondImgSrc: require('@/assets/images/home/ys/banner-one-second.png'),
          bgImgSrc: require('@/assets/images/home/v2/banner1.png')
        },
        {
          firstImgSrc: require('@/assets/images/home/ys/banner-one-first.png'),
          secondImgSrc: require('@/assets/images/home/ys/banner-one-second.png'),
          bgImgSrc: require('@/assets/images/home/v2/banner2.png')
        },
        {
          firstImgSrc: require('@/assets/images/home/ys/banner-one-first.png'),
          secondImgSrc: require('@/assets/images/home/ys/banner-one-second.png'),
          bgImgSrc: require('@/assets/images/home/v1/banner444.png')
        }
      ],
      advantages: [
        {
          title: '控本',
          desc: ['集中采购', '产品研发'],
          bg: require('@/assets/images/home/v1/advantage1.png'),
          hoverBg: require('@/assets/images/home/v1/advantage11.png'),
          hover: [
            {
              title: '解决采购价格高',
              desc: '集中采购，品好价优全程监控，全品类涵盖，省心省力。'
            },
            {
              title: '解决产品无保障',
              desc: '新品研发，爆款制造售后保障，质量可靠。'
            },
          ]
        },
        {
          title: '增效',
          desc: ['仓配一体', '电商运营'],
          bg: require('@/assets/images/home/v1/advantage2.png'),
          hoverBg: require('@/assets/images/home/v1/advantage22.png'),
          hover: [
            {
              title: '解决物流不及时',
              desc: '恒定三温仓存储，全国物流网络，全时段待命，极速送达。'
            },
            {
              title: '提升门店营业额',
              desc: '打造消费画像升级，破译流量密码，互联网平台最新玩法，品牌门店营业额增长。'
            }
          ]
        }
      ],
      business: [
        {
          idx: '01',
          title: '火锅食材类',
          imgSrc: require('@/assets/images/home/v1/business1.png')
        },
        {
          idx: '02',
          title: '耗材类',
          imgSrc: require('@/assets/images/home/v1/business2.png')
        },
        {
          idx: '03',
          title: '底油底料类',
          imgSrc: require('@/assets/images/home/v1/business3.png')
        },
        {
          idx: '04',
          title: '酒水饮料类',
          imgSrc: require('@/assets/images/home/v1/business4.png')
        },
        {
          idx: '05',
          title: '调味料类',
          imgSrc: require('@/assets/images/home/v1/business5.png')
        }
      ],
      brands: [
        {
          bg: require('@/assets/images/home/v2/brand1.png')
        },
        {
          bg: require('@/assets/images/home/v2/brand1.png')
        },
        {
          bg: require('@/assets/images/home/v2/brand1.png')
        }
      ],
      news: [
        {
          imgSrc: require('@/assets/images/home/v1/news1.png'),
          title: '足球×？ 才是欧洲杯！',
          link: '',
          isActive: false
        },
        {
          imgSrc: require('@/assets/images/home/v1/news2.png'),
          title: '至爱·至灿',
          link: '',
          isActive: true
        },
        {
          imgSrc: require('@/assets/images/home/v1/news3.png'),
          title: '浓情端午，浅念安康！',
          link: '',
          isActive: false
        },
        {
          imgSrc: require('@/assets/images/home/v1/news1.png'),
          title: '足球×？ 才是欧洲杯！',
          link: '',
          isActive: false
        }
      ],
      timer: null
    }
  },
  computed: {

  },
  watch: {

  },
  methods: {
    carouselChange(index) {
      this.currentBannerIndex = index
    },
    setIndicator(index) {
      this.$refs.bannerCarousel.setActiveItem(index)
    },
    setIndicatorNext() {
      this.$refs.bannerCarousel.next()
    },
    scrollNews(direction) {
      this.$refs.news.scrollLeft += direction === 'left' ? -500 : 500
      let half = window.screen.width / 2
      if (this.timer) clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.news.forEach((item, index) => {
          let { left, right } = this.$refs['newsItem' + index].getBoundingClientRect()
          item.isActive = false
          if (left < half && right >= half) {
            item.isActive = true
          }
        })
      }, 500)
    },
    setNewsActive(idx) {
      this.news.forEach((item, index) => {
        item.isActive = idx === index ? true : false
      })
    },
    jump(path) {
      this.$router.push(path)
    },
    viewNews() {
      // this.$refs.newsOne.show()
    }
  }
}
</script>
<style lang="scss" scoped>
.banner-carousel {
  height: 39vw;
  position: relative;
  .carousel-item {
    background-repeat: no-repeat;
    background-size: 100% 100%;
    color: white;
    .banner-carousel--content {
      position: relative;
      height: 100%;
      .banner-carousel--arrow {
        position: absolute;
        bottom: 14vw;
        left: 20vw;
        width: 3.43vw;
        height: 3.43vw;
        cursor: pointer;
      }
    }
  }
  .banner-indicator {
    position: absolute;
    top: 50%;
    right: 4rem;
    z-index: 100;
    transform: translateY(-50%);
    .banner-indicator--line {
      position: relative;
      width: 0;
      border-left: 2px solid rgba(255, 255, 255, 0.5);
      border-radius: 2px;
      .banner-indicator--dot {
        position: absolute;
        left: -1px;
        .banner-indicator--dot_show {
          position: absolute;
          left: 0;
          top: 0;
          transform: translate(-50%, -50%);
          width: 3.5rem;
          height: 3.5rem;
          line-height: 3.5rem;
          text-align: center;
          border-radius: 50%;
          color: white;
          background-color: #e12f22;
        }
        .banner-indicator--dot_hide {
          position: absolute;
          left: 0;
          top: 0;
          transform: translate(-50%, -50%);
          width: 0.8rem;
          height: 0.8rem;
          border: 2px solid rgba(255, 255, 255, 0.5);
          border-radius: 50%;
          background-color: #a3a8ac;
          cursor: pointer;
        }
      }
    }
  }
}
.breif {
  height: 18rem;
  .breaf-content {
    margin-top: 6rem;
    font-size: 2rem;
    font-weight: 500;
    text-align: center;
  }
}
.advantage {
  height: 46vw;
  background-image: url('../assets/images/home/v1/advantage-bg.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  .advantage-content {
    margin-top: 9rem;
    display: flex;
    .advantage-content--item {
      position: relative;
      width: 50vw;
      height: 32.65vw;
      &:hover {
        .advantage-content--item_f {
          opacity: 1;
          transform: scale(1);
        }
      }
      .advantage-content--item_c {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-position: -3vw -3vw;
        background-size: 120% 120%;
        background-repeat: no-repeat;
        .advantage-content--item_cc {
          color: white;
          text-align: center;
          width: 13vw;
          height: 13vw;
          border-radius: 2.6vw;
          background-color: #e12f22;
          .advantage-content--item_ct {
            font-size: 3vw;
            font-weight: bold;
            margin-top: 2.29vw;
            margin-bottom: 1.5vw;
          }
          .advantage-content--item_ci {
            font-size: 0.8vw;
            margin-bottom: 1vw;
          }
        }
      }
      .advantage-content--item_f {
        opacity: 0;
        transform: scale(0.1);
        transform-origin: center;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10;
        background-position: -3vw -3vw;
        background-size: 120% 120%;
        background-repeat: no-repeat;
        transition: all 0.3s linear;
        .advantage-content--item_fb {
          position: absolute;
          left: 8.5vw;
          top: 50%;
          transform: translateY(-50%);
          color: #333333;
        }
        .advantage-content--item_fc {
          width: 30vw;
          background-color: white;
          border-radius: 2rem;
          padding: 0.78vw 1.5vw;
          margin-bottom: 1.35vw;
          .advantage-content--item_ft {
            font-size: 0.93vw;
            font-weight: bold;
            margin-bottom: 0.5vw;
          }
          .advantage-content--item_fi {
            font-size: 0.83vw;
            line-height: 1.6vw;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
          }
        }
      }
    }
  }
}
.business {
  height: 43.5vw;
  background-image: url('../assets/images/home/v1/business-bg.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  .business-content {
    display: flex;
    color: #1a1a1a;
    font-weight: bold;
    margin-top: 7.8vw;
    justify-content: space-around;
    padding: 0 0.8vw;
    .business-item {
      flex: 0 1 auto;
      width: 18vw;
      height: 20.8vw;
      text-align: center;
      background-color: white;
      padding-top: 4vw;
      box-sizing: border-box;
      .business-item--title {
        margin-bottom: 1.04vw;
        &:nth-child(1) {
          font-size: 1.56vw;
        }
        &:nth-child(2) {
          font-size: 1.25vw;
        }
      }
      .business-item--img {
        width: 8.3vw;
        height: 8.8vw;
      }
    }
  }
}
.brand ::v-deep .el-carousel__arrow {
  color: #e61d0e;
  background-color: white;
  border: 1px dotted #e61d0e;
  width: 4.4rem;
  height: 4.4rem;
  line-height: 4.4rem;
  font-size: 3.2rem;
}
.brand ::v-deep .el-icon-arrow-right {
  font-weight: bold;
}
.brand ::v-deep .el-icon-arrow-left {
  font-weight: bold;
}
.brand {
  background: white;
  .brand-content {
    margin-top: 9rem;
    .brand-carousel-item {
      padding: 1.5vw 0 0;
      box-sizing: border-box;
      .brand-carousel-item_c {
        width: 90vw;
        height: 22.5vw;
        margin: 0 4.68vw;
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }
    }
  }
}
.news {
  height: 26vw;
  .news-content {
    position: relative;
    margin-top: 4vw;
    padding-top: 1.15vw;
    .news-content-scroll {
      width: 100%;
      white-space: nowrap;
      overflow-x: auto;
      overflow-y: hidden;
      scroll-behavior: smooth;
      padding-top: 2.8vw;
      &::-webkit-scrollbar {
        width: 0;
        height: 0;
      }
    }
    .new-content-arrow {
      position: absolute;
      top: 50%;
      z-index: 100;
      transform: translateY(-50%);
      font-size: 3.5rem;
      width: 4.4rem;
      height: 4.4rem;
      line-height: 4.4rem;
      text-align: center;
      border: 1px dotted white;
      border-radius: 50%;
      background-color: rgba(0, 0, 0, 0.05);
      cursor: pointer;
    }
    .new-content-arrow--left {
      left: 16px;
      svg {
        transform: translateX(-2px) rotate(90deg);
      }
    }
    .new-content-arrow--right {
      right: 16px;
      svg {
        transform: translateX(2px) rotate(-90deg);
      }
    }
    .news-content--item {
      display: inline-block;
      margin-right: 3.64vw;
      vertical-align: top;
      &:first-child {
        margin-left: 3.64vw;
      }
      .news-content--item_img {
        width: 23.6vw;
        height: 9.27vw;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        margin-bottom: 2rem;
        box-shadow: 5px 13px 34px rgba(0, 0, 0, 0.25);
      }
      .news-content--item_info {
        width: 100%;
        white-space: normal;
        word-break: break-all;
        .news-content--item_it {
          width: 15.6vw;
          display: inline-block;
          margin: 0 3.9vw;
          color: #333333;
          font-size: 1.04vw;
          line-height: 1.56vw;
        }
        .news-content--item_ll {
          text-align: right;
          padding-right: 4.16vw;
          transform: translateY(-1.04vw);
          .news-content--item_lv {
            color: #002b9e;
            font-size: 1.4rem;
            margin-bottom: 1.3rem;
            cursor: pointer;
          }
          .news-content--item_ld {
            font-size: 1.2rem;
          }
        }
      }
    }
    .news-content--item_active {
      transform: translateY(-2.8vw);
      transform-origin: center center;
      .news-content--item_img {
        width: 33.75vw;
        height: 13.2vw;
        margin-bottom: 0.5vw;
      }
      .news-content--item_info {
        .news-content--item_it {
          width: 16.5vw;
          margin: 0 6vw;
        }
        .news-content--item_ll {
          transform: translateY(-2.8vw);
          padding-right: 6.8vw;
        }
      }
    }
  }
}
.area-title {
  text-align: center;
  .title {
    position: relative;
    font-size: 2.8rem;
    font-weight: bold;
    color: #000000;
    &::before {
      position: absolute;
      top: 5.4rem;
      left: 50%;
      transform: translateX(-50%);
      content: '';
      width: 10rem;
      height: 0.4rem;
      background-color: #e61d0e;
      border-radius: 0.2rem;
    }
  }
}
.zone {
  padding: 4.9rem 0 0;
}
</style>
