<template>
  <div class="header">
    <div class="left">
      <img
        class="logo"
        src="../../assets/images/home/logo.png"
      >
    </div>
    <div class="body">
      <div class="nav">
        <ZcMenu :nav="nav" />
      </div>
    </div>
    <div class="right">
      <!-- <ZcInput /> -->
    </div>
  </div>
</template>

<script>
import ZcMenu from '@/components/ZcMenu.vue'
// import ZcInput from '@/components/ZcInput.vue'
export default {
  name: "Header",
  components: {
    ZcMenu,
    // ZcInput
  },
  data() {
    return {
      nav: [
        {
          path: '/main/home',
          name: '首页',
        },
        {
          path: '/main/strength',
          name: '至灿实力',
          children: [
            {
              path: '/main/about',
              name: '关于至灿',
            },
            {
              path: '/main/advantage',
              name: '核心优势',
            },
            {
              path: '/main/business',
              name: '业务分布',
            },
            {
              path: '/main/brand',
              name: '服务品牌'
            }
          ]
        },
        {
          path: '/main/product',
          name: '至灿产品',
          // children: [
          //   {
          //     path: '/main/product-food',
          //     name: '食材类',
          //   }
          // ]
        },
        {
          path: '/main/news',
          name: '至灿新闻',
        },
        {
          path: '/main/join',
          name: '加入至灿'
        }
      ]
    }
  }
}
</script>
<style lang="scss" scoped>
.header {
  height: 8rem;
  display: flex;
  align-items: center;
  background: #e61d0e;
  color: white;
  .left {
    margin-left: 4.5vw;
    .logo {
      width: 7.5rem;
      height: 4.3rem;
    }
  }
  .body {
    flex: 1;
    display: flex;
    justify-content: center;
  }
  .right {
    margin-right: 4.2vw;
  }
}
.nav {
  display: inline-block;
}
</style>
