<template>
  <div>
    <Header class="header" />
    <div class="page">
      <router-view />
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from '@/components/header/index'
import Footer from '@/components/footer/index'
export default {
  name: "Main",
  components: {
    Header,
    Footer
  },
  watch: {
    '$route.path': function () {
      document.documentElement.scrollTop = 0
    }
  }
}
</script>
<style lang="scss" scoped>
.header {
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 1000;
}
.page {
  padding-top: 8rem;
}
</style>
