<template>
  <div class="footer">
    <div class="footer-link">
      <template
        v-for="(item, index) in links"
        :key="index"
      >
        <div>
          <p class="footer-link--title">{{item.title}}</p>
          <p
            v-for="(row, idx) in item.locations"
            :key="idx"
            class="footer-link--location"
          >
            {{row.name}}
          </p>
        </div>
      </template>
      <img
        class="qr-code"
        src="../../assets/images/home/qr-code.png"
      >
    </div>
    <div class="footer-contact">
      <div class="footer-contact--item">
        <img src="../../assets/images/home/phone-icon.png">
        <span class="footer-contact--item_info">全国客户服务热线：00-0000-0000</span>
      </div>
      <div class="footer-contact--item">
        <img src="../../assets/images/home/email-icon.png">
        <span class="footer-contact--item_info">邮箱：zhican@cdzhican.com</span>
      </div>
      <div class="footer-contact--item">
        <span class="footer-contact--item_info">
          <img src="../../assets/images/home/v1/gongan.png">
          川公网安备 51010402001266号
        </span>
      </div>
      <div class="footer-contact--item">
        <span
          class="footer-contact--item_info"
          @click="locateTo"
        >蜀ICP备2021015480号</span>
      </div>
      <!-- <div class="footer-contact--item">
        <img src="../../assets/images/home/weixin-icon.png">
        <img src="../../assets/images/home/qq-icon.png">
        <img src="../../assets/images/home/weibo-icon.png">
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  components: {

  },
  data() {
    return {
      links: [
        {
          title: '关于我们',
          locations: [
            {
              name: '公司简介',
              href: ''
            },
            {
              name: '企业文化',
              href: ''
            },
            {
              name: '核心优势',
              href: ''
            },
            {
              name: '业务范围',
              href: ''
            }
          ]
        },
        {
          title: '至灿实力',
          locations: [
            {
              name: '业务板块',
              href: ''
            },
            {
              name: '旗下公司',
              href: ''
            },
            {
              name: '仓储分配',
              href: ''
            }
          ]
        },
        {
          title: '至灿产品',
          locations: [
            {
              name: '卤味产品',
              href: ''
            },
            {
              name: '水发货产品',
              href: ''
            },
            {
              name: '牛羊肉产品',
              href: ''
            },
            {
              name: '畅销产品',
              href: ''
            }
          ]
        },
        {
          title: '合作案列',
          locations: [
            {
              name: '谭鸭血',
              href: ''
            },
            {
              name: '炊二哥',
              href: ''
            },
            {
              name: '贤合庄',
              href: ''
            },
            {
              name: '楠柏弯',
              href: ''
            },
            {
              name: '蛙三',
              href: ''
            },
            {
              name: '京•祥和轩',
              href: ''
            },
            {
              name: '灶门坎',
              href: ''
            },
            {
              name: '天然呆',
              href: ''
            }
          ]
        },
        {
          title: '至灿新闻',
          locations: [
            {
              name: '基本信息',
              href: ''
            },
            {
              name: '通知公告',
              href: ''
            },
            {
              name: '公司治理',
              href: ''
            },
            {
              name: '投资者来访',
              href: ''
            }
          ]
        },
        {
          title: '加入我们',
          locations: [
            {
              name: '招聘信息',
              href: ''
            },
            {
              name: '合作联系',
              href: ''
            }
          ]
        }
      ]
    }
  },
  methods: {
    locateTo() {
      window.open('https://beian.miit.gov.cn', '_blank')
    }
  }
}
</script>
<style lang="scss" scoped>
.footer {
  .footer-link {
    position: relative;
    color: white;
    display: flex;
    justify-content: space-between;
    background-color: #1f1f1f;
    padding: 10rem 18vw 4.8rem;
    &--title {
      font-size: 2rem;
      margin-bottom: 3rem;
    }
    &--location {
      font-size: 1.6rem;
      line-height: 2.5rem;
    }
    .qr-code {
      position: absolute;
      right: 18vw;
      bottom: 3rem;
      width: 10.2rem;
      height: auto;
    }
  }
  .footer-contact {
    color: #fefefe;
    height: 6rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #1a1a1a;
    padding: 0 17.5vw;
    &--item {
      position: relative;
      margin-right: 1vw;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      img {
        width: auto;
        height: 1.25vw;
        vertical-align: middle;
      }
      &_info {
        font-size: 0.72vw;
      }
      &:nth-child(1) {
        // margin-right: 1vw;
        // overflow: hidden;
        // text-overflow: ellipsis;
        // white-space: nowrap;
      }
      &:nth-child(2) {
        // margin-right: 4vw;
      }
      &:nth-child(3) {
        // margin-right: 1vw;
      }
      &:nth-child(4) {
        cursor: pointer;
      }
      // &:last-child {
      //   position: absolute;
      //   right: 17.5vw;
      //   img {
      //     width: 4rem;
      //     height: auto;
      //     margin: 0 0.3rem;
      //   }
      // }
    }
  }
}
</style>
