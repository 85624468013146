
import Home from "@/views/Home.vue"

export default [
  {
    path: 'home',
    name: 'home',
    component: Home,
    meta: {
      title: '首页'
    }
  },
  {
    path: 'strength',
    name: 'strength',
    redirect: '/about',
    component: Home,
    meta: {
      title: '至灿实力'
    }
  },
  {
    path: 'about',
    name: 'about',
    component: () => import('@/views/strength/about/index'),
    meta: {
      title: '关于至灿'
    }
  },
  {
    path: 'advantage',
    name: 'advantage',
    component: () => import('@/views/strength/advantage/index'),
    meta: {
      title: '核心优势'
    }
  },
  {
    path: 'business',
    name: 'business',
    component: () => import('@/views/strength/business/index'),
    meta: {
      title: '业务分布'
    }
  },
  {
    path: 'brand',
    name: 'brand',
    component: () => import('@/views/strength/brand/index'),
    meta: {
      title: '服务品牌'
    }
  },
  {
    path: 'product',
    name: 'product',
    component: Home,
    meta: {
      title: '至灿产品'
    }
  },
  {
    path: 'product-food',
    name: 'product-food',
    component: () => import('@/views/product/food/index'),
    meta: {
      title: '食材类'
    }
  },
  {
    path: 'news',
    name: 'news',
    component: Home,
    meta: {
      title: '至灿新闻'
    }
  },
  {
    path: 'join',
    name: 'join',
    component: () => import('@/views/join/job/index'),
    meta: {
      title: '加入至灿'
    }
  }
]