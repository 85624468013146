import { createApp } from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import '@/assets/sass/index.scss'
import 'element-plus/packages/theme-chalk/src/base.scss'

const app = createApp(App)

import { ElCarousel, ElCarouselItem, ElTable, ElTableColumn } from 'element-plus'
app.component(ElCarousel.name, ElCarousel)
app.component(ElCarouselItem.name, ElCarouselItem)
app.component(ElTable.name, ElTable)
app.component(ElTableColumn.name, ElTableColumn)

app.use(store).use(router).mount("#app")
