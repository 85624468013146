<template>
  <div class="frame">

    <router-view />

  </div>
</template>

<script>
export default {
  name: "Frame",
  components: {

  },
}
</script>
<style scoped>
.frame {
  font-size: 1.4rem;
}
</style>
